#mobile-menu {
	@apply opacity-0 hidden;
}

body.mm-ocd-opened {
	@apply fixed w-full;
}

.mm ul.mm--parent {
	left: -100%;
	overflow-y: hidden;
}

.mm-ocd {
	top: 88px; 

	#mobile-menu {
		@apply opacity-100 block;
	}

	&__content {
		@apply bg-offBlack w-full;
		max-width: none;
	}

	&__backdrop {
		@apply hidden;
	}

	.mm-spn.mm-spn--navbar ul {
		top: 52px;
	}

	.mm-spn.mm-spn--navbar ul::before {
		@apply border-none;
	}

	.mm-spn.mm-spn--light {
		@apply bg-offBlack;
	}

	.mm-spn li.parent-li:before {
			@apply opacity-100;
		}

		.mm-spn li {
			> a {
				width: 100%;
			}
			&.parent-li {
				> a {
					width: calc(100% - 48px);
				}
				ul {
					opacity: 0;
	
					&.mm-spn--open {
						opacity: 1;
					}
				}
			}
		}

	.mm-spn a {
		@apply text-white py-3 text-lg font-proxSemi border-b-4 pl-0 border-solid border-dkGrey;

		&.active {
			@apply font-bold;
		}
	}

	.mm-spn li::after {
		@apply ml-0 opacity-100 border-t-0;
	}

	.mm-spn a:not(:last-child)::after {
		@apply border-none;
	}

	.mm-spn li {
		@apply px-6;

		&.active {
			> a {
				@apply font-bold;
			}
		}
		&.parent-li {
			&:before {
				background: url(/img/svg/chevron-right-regular.svg) no-repeat #444444;
				background-size: 14px auto;
				background-position: center;
				width: 48px;
				height: 100%;
				@apply opacity-100 border-none;
				transform: rotate(0deg);
				top: 0;
				right: 24px;
			}
		}
	}

	.mm-spn.mm-spn--navbar::before {
		background: url(/img/svg/chevron-left-regular.svg) no-repeat;
		background-size: 9px 22px;
		background-position: center;
		width: 20px;
		height: 25px;
		transform: rotate(0deg);
		top: 21px;
		@apply z-20 opacity-100 border-none;
	}

	.mm-spn.mm-spn--navbar::after {
		@apply opacity-100 text-white text-left pl-12 font-proxSemi text-lg;
		height: 48px;
		top: 21px;
		content: "Back" !important;
	}

	.mm-spn.mm-spn--navbar.mm-spn--main::after {
		@apply opacity-0;
	}

	.mm-spn {

		.mobile-btns {
			z-index: 9999;
			max-width: 400px;
			left: 50%;
            transform: translateX(-50%);
			@apply w-full absolute bottom-0 px-8 mb-6 hidden;

			.contact-phone {
	            @apply text-red uppercase;

	            svg {
	                @apply text-white relative z-10;
	            }

	            a {
	                @apply relative border-none p-0 inline-flex items-center justify-center;
	                &:before {
	                    content: "";
	                    @apply absolute bg-red w-8 h-8 rounded-full bg-red top-0 left-0 z-0 -mt-0.5 -ml-2 transition-all duration-300;
	                }
	            }
        	}
		}
	}

	.mm-spn--main {
		.mobile-btns { 
			@apply block;
		}
	}
}

.mm-ocd-opened {
	.layout {
		opacity: 0.4;
	}

	.open-nav {
		@apply opacity-0;
	}
}

.mm-ocd--open {
	.mm-ocd__content {
		&:before {
			@apply opacity-100;
		}
	}
}