$hamburger-padding-x: 0;
$hamburger-padding-y: 0px;
$hamburger-types: (squeeze);
$hamburger-layer-color: #1D1D1B;
$hamburger-layer-border-radius: 2px;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: #1D1D1B;
$hamburger-layer-width: 28px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-height: 4px;

.section-header {
	.hamburger {
		@apply mt-1 mr-4;
	}
}

.home {
	.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
		@apply bg-white;
	}

	.hamburger.is-active .hamburger-inner::before,
	.hamburger.is-active .hamburger-inner::after,
	.hamburger.is-active .hamburger-inner {
		@apply bg-white;
	}
}